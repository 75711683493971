<template>
  <div class="desc-wrapper decision-wrapper">
    <vue-custom-scrollbar class="scroll-area" v-if="decisions.length">
      <h2>गाउँसभाका निर्णयहरु</h2>
        <div
          class="decision-detail"
          v-for="(ele, index) in decisions"
          :key="index"
        >
          <p>{{ ele.decision }}</p>
        </div>
        <a href="#" class="see-more"
          >गाउँसभाका निर्णयहरु सम्बन्धित अधिक जानकारीका लागि यहाँ जानुहोस्
          <unicon name="sign-out-alt"></unicon>
        </a>
    </vue-custom-scrollbar>
    <NoDataFound v-else />
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  components: {
    vueCustomScrollbar
  },
  computed: {
    decisions() {
      let fiscalYear = this.$store.state.fiscal_year;

      return this.$store.state.decisions.filter((ele) => {
        if (ele.fiscalyear === fiscalYear) return ele;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.decision-detail {
  padding-right: 80px;
  @media all and (max-width: 3199px) {
    padding-right: 40px;
  }
}
</style>
